import React from "react";
import "./Whoarewe.css"
import SB from "../../assets/whoarewe/sb.png";

const Whoarewe = () => {
  return (
    <div className="root">
      <img id="logo" src={SB}/>
      <div className="text">
        <h1 className="title">Who are we?</h1>
        <p className="para">IEEE ENET’Com Student Branch was founded on November 11th, 2013 by Mehdi Lajmi. What began as a torch of enthusiasm with just 13 members has risen to stand tall among the Tunisia section with more than 400 members.</p>
      </div>
    </div>
  );
};

export default Whoarewe;
