import React, { useEffect } from 'react';
import Header from '../components/home/Header';
import Whoarewe from '../components/home/Whoarewe';
import Album from '../components/home/Album';
import Seperator from '../components/home/Seperator';
import Chapters from '../components/home/Chapters';
import Footer from '../components/Footer';
import NewExecBoard from '../components/home/NewExecBoard';
import Numbers from '../components/home/Numbers';


const Home = () => {
   
    return (
        <div>
            <Header />
            <Seperator />
            <Whoarewe />
            <Album />
            <NewExecBoard/>
            <Numbers />
            <Chapters />
            <Footer />
        </div>
    );
}
export default Home;