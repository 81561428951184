import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations";
import './PreLoader.css'
import logosb from "../assets/preloader/logosb.png"


const PreLoader = () => {
    useEffect(() => {
        preLoaderAnim();
      }, []);


  return (
    <div className="preloader">
        
      <div className="texts-container">
        <span>TEAMS</span>
        <span>BUILD</span>
        <span>DREAMS</span>
      </div>
    </div>
  );
};

export default PreLoader;