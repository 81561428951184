import React from "react";
import Header from "../components/aboutus/Header";
import Section from "../components/aboutus/Section";
import Footer from "../components/Footer";
import ieeeLogo from "../assets/ieeelogos/ieee.jpg";
import enetcomsb1 from "../assets/ieeelogos/enetcomsb1.jpg";
import ieeer8 from "../assets/ieeelogos/ieeer8.jpg";
import tunisiasec from "../assets/ieeelogos/tunisiasec.jpg";
import backgroundChapters from "../assets/chapterslogo/chapters.png";

const About = () => {
  const content = [
    {
      img: ieeeLogo,
      description:
        "IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity. IEEE and its members inspire a global community through its highly cited publications, conferences, technology standards, and professional and educational activities.",
    },

    {
      img: ieeer8,
      description:
        "Region 8 covers covers Europe, Middle East and Africa, is an effectively organized, dynamic and influential entity, recognized as a leading and esteemed part of a truly transnational IEEE, with IEEE benefits equally available to all members.",
    },
    {
      img: tunisiasec,
      description:
        "its Mission is To serve the needs of the members of the Institute, the profession and society at large, by enhancing and supporting the IEEE's Organizational Units and their activities within Region 8",
    },
    {
      img: enetcomsb1,
      description:
       'IEEE ENET’Com Student Branch was founded on November 11th, 2013 by Mehdi Lajmi. What began as a torch of enthusiasm with just 13 members has risen to stand tall among the Tunisia section with more than 300 members.IEEE ENETCOM SB is flourishing thanks to the efforts of successive generations since 2016.  As a modest SB, our major concern is to make a difference in the world, in our country, and in our city, all starting with our school. In fact, “small acts, when multiplied by millions of people, can transform the world” Howard Zinn. Therefore, we give similar interests in soft skills development as in acquiring technical knowledge. We do focus on thinking outside the box and keeping pace with the rapid developments of technology. Only in this way, do we fulfill our duty towards IEEE ENETCOM SB members.',
    },
  ];
  return (
    <div>
      <Header
        background={
          backgroundChapters
        }
        text="About us"
      />
      {content.map((item, i) =>
      
          <Section
            key={i}
            img={item.img}
            description={item.description}
          />
        
      )}
      <Footer />
    </div>
  );
};

export default About;
