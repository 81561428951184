import React, { useEffect, useState } from "react";
import {NavLink} from "react-router-dom";
import "./Navbar.css";
import LOGO1 from "../assets/navbar/IEEE_MB_tag_white_RGB.png"
import LOGO2 from "../assets/navbar/IEEE_MB_tag_white_RGB1.png"
const NavBar = () => {
    const [colorBackground, setColorBackground] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [logoSrc, setLogoSrc] = useState("");
    useEffect(() => {
        window.addEventListener('scroll',  () => {
            if (window.scrollY > 50) {
                setColorBackground(true);
            } else {
                setColorBackground(false);
            }
        });
    },[window.scrollY]);

    let activePage = {
        borderBottom: "3px solid #fff",
    };
    let activeLogo ={
        border :"3px solid #0813a5",
    }
    const handleShowMenu = () => {
        setShowMenu((prev) => !prev);
    }
    const menuItems1 = [
        {
            name: "About Us",
            link: "/about",
            id: "about",
        },
        
        {
            name: "Activities",
            link: "/activities",
            id: "services",
        },
        // {
        //     name: "IEEE",
        //     link: "/",
        //     id: "home",
        // },
    ];

    const menuItems2 = [
        {
            name: "Chapters & AG",
            link: "/chapters",
            id: "chapters",
        },
        
        {
            name: "Contact",
            link: "/contact",
            id: "contact",
        },
        
    ];


    return (

        <div className={`NavBar ${showMenu ? "show-nav" : "hidden-nav"} ${colorBackground ? "bg-color-navbar" : ""}`}>
            <div className="Left" >
            </div>
            <div className="center">
                {menuItems1.map((item) => (

                    <NavLink
                        className="item"
                        key={item.id}
                        to={item.link}
                        style={({ isActive }) =>
                            isActive ? activePage : undefined }>
                        {item.name}
                    </NavLink>

                ))}
            <NavLink to="/" className="logo"  style={({ isActive }) =>
       isActive ? setLogoSrc(LOGO1):setLogoSrc(LOGO2) }>
            <div className='centerlogo'>
        <img height={60} src={logoSrc}/>
      </div>
            </NavLink>
                {menuItems2.map((item) => (

                    <NavLink
                        className="item"
                        key={item.id}
                        to={item.link}
                        style={({ isActive }) =>
                            isActive ? activePage : undefined }>
                        {item.name}
                    </NavLink>

                ))}
            </div>
            <div className="Right">
                <button className="btn"/>
            </div>
            <div className="hamburger "  onClick={handleShowMenu}>
                <div className="line1"> </div>
                <div className="line2"> </div>
                <div className="line3"> </div>
            </div>
        </div>

    );
};

export default NavBar;