import React from "react";

import { Route,Routes } from "react-router-dom";
import Home from "./pages/Home";
import Chapters from "./pages/Chapters";
import ContactUS from "./pages/ContactUs";
import Activities from "./pages/Activities";
import Layout from "./components/Layout";
import About from "./pages/About";
import PreLoader from "./components/PreLoader";


function App() {
    return (
        <>
        <PreLoader/>
        <div style={{margin:'0'}}>

            <Layout>


                <Routes >
                    <Route path="/" element={<Home/>} />
                    <Route path="/chapters" element={<Chapters/>} />
                    <Route path="/contact" element={<ContactUS/>} />
                    <Route path="/activities" element={<Activities/>} />
                    <Route path="/about" element={<About/>} />


                </Routes>
            </Layout>


        </div>
        </>
    );
}

export default App;