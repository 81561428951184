import React from "react";
import "./Card.css";

const Card = ({ img, description, color,link}) => {
    
        return (
           
            
            <div
                className="card-container"
                  
            > 
         <a href={link}><img src={img} /></a>
                
         
               
        <div className="card-text" style={{backgroundColor:color}}>{description}</div>
    </div>
        
        )
};

export default Card;
