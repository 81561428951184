import React from "react";
import "./Seperator.css";

const Seperator = () => {
    return (
      <center>
      <div className="sep">
      </div>
      </center>
    );
  };
  
  export default Seperator;