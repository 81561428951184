import React from "react";
import Header from "../components/aboutus/Header";
import Card from "../components/chapters/Card";
import IAS from "../assets/chapterslogo/ias.png";
import PES from "../assets/chapterslogo/pes.png";
import EMBS from "../assets/chapterslogo/embs.png";
import CAS from "../assets/chapterslogo/cas.png";
import RAS from "../assets/chapterslogo/ras.png";
import CS from "../assets/chapterslogo/cs.png";
import WIE from "../assets/chapterslogo/wie.png";
import Footer from "../components/Footer";
import BgImage from "../assets/backgrounds/bkg_2.png";
import backgroundChapters from "../assets/chapterslogo/chapters.png";
import "./Chapters.css";
const Chapters = () => {
  const content = [
    {
      img: CS,
      description:
        "Engaging computer engineers, scientists, academia, and industry professionals from all areas of computing, the IEEE Computer Society (CS) sets the standard for the education and engagement that fuels continued global technological advancement. Through conferences, publications, and programs, and by bringing together computer science and engineering leaders at every phase of their career for dialogue, debate, and collaboration, IEEE CS empowers, shapes, and guides the future of not only its members, but the greater industry, enabling new opportunities to better serve our world",
      color: "#f99908",
      link: " https://computer-enetcom.ieee.tn/",
    },
    {
      img: EMBS,
      description:
        "The IEEE EMBS ENET’Com SBC was founded in Enet’Com on April 2018, by Miss Ikram Cherni. IEEE Engineering in Medicine and Biology Society: EMBS, is the world’s largest international society of biomedical engineers. It provides its members with access to the people, practices, information and opinions that are shaping one of the fastest-growing fields in science.The EMBS field of interest is the development and application of engineering concepts and methods to biology, medicine and health sciences to provide effective solutions to biological, medical and healthcare problems. EMBS advances the application of engineering sciences and technology to medicine and biology, promotes the profession, and provides global leadership for the benefit of its members and humanity by disseminating knowledge, setting standards, fostering professional development, and recognizing excellence.IEEE EMBS is the leading publisher of original research in biomedical engineering with 11 technical journals and transactions. It also sponsors several scientific conferences which are considered a great opportunity to create networking and employment opportunities.",
      color: "#0eccd1",
      link: " https://embs-enetcom.ieee.tn/",
    },
    {
      img: IAS,
      description:
        "IEEE Industry applications society ENET'Com Student Branch chapter was approved on March 17, 2018 and has reached 163 members until now.IEEE Industry Applications Society enables the advancement of theory and practice in the design, development, manufacturing and application of safe, sustainable, reliable, smart electrical systems, equipment and services.",
      color: "#008d49",
      link: "https://ias-enetcom.ieee.tn/",
    },
    {
      img: RAS,
      description:
        "The IEEE Robotics and Automation Society's objectives are scientific, literary and educational in character. The Society strives for the advancement of the theory and practice of robotics and automation engineering and science and of the allied arts and sciences, and for the maintenance of high professional standards among its members, all in consonance with the Constitution and Bylaws of the IEEE and with special attention to such aims within the Field of Interest of the Society",
      color: "#5f246a",
      link: "https://ras-enetcom.ieee.tn/ ",
    },
    {
      img: PES,
      description:
        "The Power & Energy Society (PES) provides the world’s largest forum for sharing the latest in technological developments in the electric power industry, for developing standards that guide the development and construction of equipment and systems, and for educating members of the industry and the general public. IEEE PES ENET’Com SBC was established in  june 2018 by its founder Amir Agerbi . there are now over 160 members. The impact and influence of the expertise and knowledge of our members have been extraordinary, as they continually make advancements across several key sectors including academia, enterprise, and government.",
      color: "#68b93a",
        link: "https:/pes-enetcom.ieee.tn/",
    },
    {
      img: CAS,
      description:
        "IEEE ENET'Com circuits and systems society student branch chapter is our newest student branch chapter, the effective date of its formation is July 27, 2022 and it has reached up to 11 student members until now. The IEEE Circuits and Systems Society is the leading organization that promotes the advancement of the theory, analysis, design, tools, and implementation of circuits and systems. Therefore, this society focuses on multiple fields such as industry, agriculture, medicine, biology, etc.In our case, we tried to work on both fields industry 4.0 and agriculture 4.0 as a fresh and promoting start of our CAS student activities.",
      color: "#00905c",
      link: " https://cas-enetcom.ieee.tn/",
    },
    {
      img: WIE,
      description:
        "The WIE ENET'Com Student Affinity Group was created in ENET'Com on September 22, 2017 by its founder Mariem Ayed who is now the president of WIE TUNISIA SECTION  As we believe that a hand never claps, but together we do something wonderful, we focus so hard to organize high quality of technical meetings to strengthen the relationship between our wie membe As a family, we do our best to develop our WIE SAG and to make this family bigger and bigger. Our main goal is  to promote women and girls in STEM domain and encourage them to follow their academic interests and get into humanitarian acts throughout our volunteer work, as we are committed to creating an encouraged, caring and supportive environment every term.",
      color: "#8444a5",
      link: " https://wie-enetcom.ieee.tn/",
    },
  ];
  return (
    <div>
      <Header
        background={
          backgroundChapters
        }
        text="Chapters & AG"
      />
      <div
        className="chapters-container"
        style={{ backgroundImage: `url(${BgImage})` }}
      >
        {content.map(
          (item, i) => (
            
            <Card
              img={item.img}
              description={item.description}
              //   direction="row"
              color={item.color}
              link={item.link}
            />
            
          )
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Chapters;
