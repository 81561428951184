import React from "react";
import "./Album.css";
import img1 from "../../assets/album/1m.jpg";
import img2 from "../../assets/album/2m.jpg";
import img3 from "../../assets/album/3m.jpg";
import img4 from "../../assets/album/4m.jpg";
import img5 from "../../assets/album/5m.jpg";
import img6 from "../../assets/album/6m.jpg";
import img7 from "../../assets/album/7m.jpg";
import img8 from "../../assets/album/8m.jpg";
import img10 from "../../assets/album/10m.jpg";
import img11 from "../../assets/album/11m.jpg";
import img12 from "../../assets/album/12m.jpg";
import img13 from "../../assets/album/13m.jpg";



const Album = () => {
  return (
    <div className="main">
      <div className="ll">
        <img src={img13} className="i13" width="300px"/>
        <img src={img12} className="i12" width="300px"/>
      </div>
      <div className="left">
        <div className="cont">
            <div className="op">
              <div className="overlay1"></div>
              <p className="par">TSYP X</p>
            </div>
            <img src={img7} className="i1" width="300px"/>
        </div>
        <div className="cont">
            <div className="op" width="100%" height="100%">
              <div className="overlay2"></div>
              <p className="par">IEEE DAY 2023</p>
            </div>
            <img src={img4} className="i2" width="300px"/>
        </div>
        <div className="cont">
            <div className="op" width="100%" height="100%">
              <div className="overlay3"></div>
              <p className="par">HIKING DAY</p>
            </div>
            <img src={img6} className="i3" width="300px"/>
        </div>

      </div>
      <div className="cent">
        <div className="cont">
            <div className="op">
              <div className="overlay4"></div>
              <p className="par">TSYP 11</p>
            </div>
            <img src={img2} className="i4" width="500px"/>
      </div>
        <div className="textalb">
            <p className="text1">MOMENTS FROM OUR</p>
            <p className="text2">FAMILY</p>
        </div>
        <div className="cont">
          <div className="op" width="100%" height="100%">
            <div className="overlay5"></div>
            <p className="par">INTEGRATION DAY</p>
          </div>
          <img src={img8} className="i5" width="500px"/>
        </div>
      </div>
      <div className="right">
      <div className="cont">
          <div className="op" width="100%" height="100%">
            <div className="overlay6"></div>
            <p className="par">CRESCENT CELEBRATION 2</p>
          </div>
          <img src={img1} className="i6" width="300px"/>
      </div>
      <div className="cont">
          <div className="op" width="100%" height="100%">
            <div className="overlay7"></div>
            <p className="par">OPENING CEREMONY</p>
          </div>
          <img src={img3} className="i7" width="300px"/>
      </div>
      <div className="cont">
          <div className="op" width="100%" height="100%">
            <div className="overlay8"></div>
            <p className="par">IEEE XTREME 17.0</p>
          </div>
          <img src={img5} className="i8" width="300px"/>
      </div>

      </div>
      <div className="rr">
        <img src={img10} className="i10" width="300px"/>
        <img src={img11} className="i11" width="300px"/>
      </div>
    </div>
  );
};

export default Album;
