import React from "react";
import "./Header.css";
import Background from "../../assets/home/Home_BG.jpg";
import Typewriter from "typewriter-effect";
const Header = () => {
  return (
    <div
      className="header-component-background"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="type-writer">
        <Typewriter
          options={{
            autoStart: true,
            cursor: "",
            delay: 40,
          }}
          onInit={(typewriter) => {
            typewriter.typeString("WELCOME TO").start();
          }}
        />
        <Typewriter
          options={{
            autoStart: true,
            cursor: "",
            delay: 40,
          }}
          onInit={(typewriter) => {
            typewriter.typeString("IEEE ENET'COM Student Branch").start();
          }}
        />
      </div>
    </div>
  );
};

export default Header;
