import Navbar from "./Navbar";
import Footer from "./Footer";
import React from "react";
function Layout({ children }) {
    return (
        <div className="App">
            <Navbar />
            {children}
        </div>
    );
}

export default Layout;
