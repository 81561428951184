import { red } from "@mui/material/colors";
import React from "react";
import { Chrono } from "react-chrono";
import c5 from "../assets/home/courassel_5.jpg";
import a1 from "../assets/activities/donsramdan.png";
import a2 from "../assets/activities/univexpo.png";
import a3 from "../assets/activities/integration.png";
import a4 from "../assets/activities/firstAG1.jpeg";
import a5 from "../assets/activities/sponsoring.jpeg";
import a6 from "../assets/activities/ieeeday1.png";
import a7 from "../assets/activities/ieeeday2.jpeg";
import a8 from "../assets/activities/ieeeday3.jpeg";
import a9 from "../assets/activities/ieeeday4.jpeg";
import a10 from "../assets/activities/bloodday.jpeg";
import a11 from "../assets/activities/ieeextreme.jpeg";
import a12 from "../assets/activities/olc.jpeg";
import a13 from "../assets/activities/iotieee.jpeg";
import a14 from "../assets/activities/tsyp.29739b38175820333687.jpg";
import a15 from "../assets/activities/newofficersmeeting.576b99700c87a3ac6f06.jpg";
import a16 from "../assets/activities/hkday.7708f2e975133c8aa389.jpg";  
import a17 from "../assets/activities/CREST.png";
import a18 from "../assets/activities/INNOVPROJECTSV2.png"; 
import a19 from "../assets/activities/iASTAM.png";
import a20 from "../assets/activities/HTB.png";
import a21 from "../assets/activities/10THANN.png";
import a22 from "../assets/activities/EXPO2.png";
import Header from "../components/aboutus/Header";
import Footer from "../components/Footer";
import backgroundChapters from "../assets/chapterslogo/chapters.png";

import "./Activities.css";

const Activities = () => {
  const content = [

    {
      title: "UnivExpo participation   ",
      description: "During the 25th edition of UnivExpo, IEEE ENETCOM SB had the honor of showcasing its presence through two stands.       The first stand, a collaborative effort with IEEE ENIS SB, IEEE FSS SB, IEEE ISIMS SB, and IEEE ESPIN SB, served as an excellent platform to introduce and familiarize visitors with IEEE and its diverse range of activities.      And the second, in coordination with ENET'Com administration, students, and clubs, was an integral part of the orientation consult provided to bachelor's and pre-engineering students from various universities in Sfax.           ",
  img: a22,
      },
    {
      title: "ENETCOM SB 10th Anniversary    ",
      description: "The atmosphere was filled with gratitude as we gathered to celebrate the 10th anniversary of IEEE ENETCOM SB. We expressed heartfelt appreciation to the former officers whose unwavering support had brought us this far, while also welcomed the new executive board with enthusiasm. The event symbolized our dedication to carry forward the student branch's legacy and foster innovation. The presence of esteemed guests from various branches added an extra layer of joy to our celebration, creating unforgettable memories and shared experiences.",
  img: a21,
      },
    {
      title: "Tunisia HTB Event 2023    ",
      description: "The Tunisia Humanitarian Technologies Board event 2023 (Tunisia HTB event 2023) was organized by IEEE EMBS ENET'Com SBC & IEEE EMBS Tunisia Section Chapter on June 24th, 2023.       The Tunisia HTB Event 2023 brought Healthcare technicians and engineers to discuss various themes and question some of the big names in medicine and engineering. Our long-term objective was to create an environment for easy mobility and communication between the two fields.",
  img: a20,
      },
    {
      title: "IASTAM3.0     ",
      description: "The IEEE IAS Tunisia Annual Meeting (IASTAM 3.0) was organized by IEEE IAS ENET'Com SBC & IEEE IAS Tunisia Section Chapter on June 1st-3rd, 2023 in Orient Palace Hotel Sousse. The event brought together students and professionals to share knowledge, network, and discuss new and innovative ideas in the industry and Artificial Intelligence field.      IASTAM 3.0 featured a job fair, a technical challenge (Computer Vision Hackathon for both IEEE and non-IEEE members), industrial visits (Leoni Sousse and Novation City),  workshops (Computer vision, Deep Learning, Impact of AI and IoT in the evolution of Industry 4.0, Boost Your Recruitment Success), and the non-technical challenge between all the IAS SBCs.           Throughout the event, attendees had the chance to connect and enlarge their network by attending a team-building activities party. Also, they had a lot of fun at the vibrant tropical-themed party.",
  img: a19,
      },
    {
      title: "INNOVPROJECT v2.0 Finals     ",
      description: "IEEE ENETCOM SB organized INNOVPROJECTS v2.0 Finals during a vibrant Ramadhanic night.     The event proved to be the perfect platform for the second edition winners to showcase their exceptional ideas, taking them from concept to prototype, and paving the way towards entrepreneurship.      It was a night of celebration and inspiration, leaving participants motivated to continue their journey of transforming groundbreaking ideas into reality.       ",
  img: a18,
      },
      {
      title: "CRESENT CELEBRATION      ",
      description: " IEEE ENETCOM SB organized the Crescent celebration that created a profound sense of togetherness, celebrating the act of breaking the fast as a united community.       After breaking the fast, a lively live band performance filled the venue with electrifying rhythms, prompting everyone to dance and sing along. The infectious melodies were simply amazing, creating an unforgettable experience for all.      The event's entertaining atmosphere left a lasting impact on all attendees, fostering stronger bonds of friendship within the IEEE ENETCOM SB community.       ",
  img: a17,
      },
    {
      title: "Hiking day 2.0      ",
      description: " On the 26th of February 2023, IEEE ENETCOM SB went hiking in the mountains of Zaghouan, Sidi Median It was an incredible journey in which non- IEEE members got to know and integrate with the IEEE family  During this experience, we achieved what we thought impossible as we climbed mountains and heights and crossed valleys and plains.Along the way, we discovered the crystal cavern and ancient relics of what used to be a crystal factory.    Besides, we tried the zipline and it was a wonderful experience.We all ate together on the shore of Lake Haroun Al - Rayhan.The view was peaceful and refreshing. By the end of the day, we were tired but filled with joy and satisfaction, proud of our achievement. ",
  img: a16,
      },
    {
      title: "New Officers meeting     ",
      description: " We are delighted to have you on the executive committee   . On behalf of all the IEEE ENETCOM SB members and officers, we would like to extend our warmest welcome and good wishes !      A new team is formed, but the spirit is the same         New visions and new strategies, but the dream is the same.      We are IEEE ENETCOM SB, the team who builds dreams.",
  img: a15,
      },
      {
        title: "TSYP X Participation      ",
        description: " We are honored to attend TSYP X (Tunisian Students and Young Professionals 10th edition) which was held on 19-20-21 December 2022.We were there as a great big family. We stand for our student branch, our history, and our identity. We were worthy to continue the glory of those who preceded us who had faith in IEEE ENET'COM SB and in the new shining generation. Also, we are delighted to announce that we have deservedly won 5 awards   :First place STAR PROGRAM WIE challenge First place CIS challenge Best Ambassador award, on this occasion, congratulations dear Med Rebai Best IEEE Day award, special thanks to our ambassador Mohamed Aziz Zouari .Finally, the best recognition, and almost the only one that redeems our efforts on a national scale, the 2022 Outstanding LARGE Student Branch Award.It was a pleasure meeting other IEEE Student Branches' members and officers whom we thank a lot for their great work all year long! We thank especially this year's hosts IEEE ESPRIT Student Branch ent Branch for the big efforts to organize the biggest IEEE Tunisia assembly for this year. And finally, our sincere felicitations to the rising shining student branch that won our hearts IEEE ESSTHS Student Branch . ",
    img: a14,
        },

  {
    title: "SMART IOT SENSORS     ",
    description:"IEEE ENETCOM SB offered its members an advanced and enhanced workshop about Smart IoT Sensors. With professional high-quality materials, the members discovered and learned about the technology of the future: the Internet of Things.The intervention of Dr. Saakshi was a huge surprise. Members are more and more aware of the opportunities that IEEE offers.",
    img: a13,
  }, {
    title: "OPEN LEARNING COMMUNITY DAY     ",
    description:"Opening up to international collaborations, IEEE enetcom SB and the Computer Society chapter is collaborating with Kulimi e.V., an enterprise based in Berlin,  to offer ENET’Com students technology challenges and tech talks.On this visit we had the chance to meet the Kulimi e.V. team :Tristen Mueller, Bochra Jegham and Sarra kaak.they spoke about: -talked about their open Learning community-AWS CLOUD practitioner certification-Launch of the Tunisian Cloud Challenge (second run)The collaboration will add value to the quality of information delivery and will give the chance to our members to get certified in cloud and network topics.",
    img: a12,
  }, {
    title: "IEEE XTREME 16.0 - ENETCOM & ISIMS ",
    description:"CS IEEE ENETCOM SB Chapter hosted successfully the internationally well-known competitive programming event: Xtreme 16.0, in collaboration with CS IEEE ISIMS SB. Also, IEEE ENETCOM SB and IEEE ISIMS SB took part in the organization and supporting the organization committee at every step.The event was a great success in which members get the chance to have fun, meet new people and work in groups. The dinner and the party were amazing sources of energy that get people to know each other and extend their network.  During coding time, participants did their best and experienced the ups and downs of working under pressure.Finally, we had breakfast together and we celebrated the IEEE Xtreme 16.0. The delicious cake was the perfect award after the hard work during the night!",
    img: a11,
  }, {
    title: "دمك غالي | YOUR BLOOD IS PRECIOUS  ",
    description:"Celebrating IEEE is a call for humanity ! Did you know that one person donating blood can save 3 lives?This is why your blood is precious for us and themAnd a drop of blood can save livesThis is why IEEE ENETCOM Student Branch took part in organizing the fourth edition of the Young Economic Chamber of Sakiyat El Daer blood donation campaign: ‘Your blood is precious' . And it was in partnership with the Regional Center for Blood Transfusion in Sfax.Of course, such a humanitarian act demands persistence and reminding to motivate students and encourage them to donate. So, this campaign will be on several other days. ",
    img: a10,
  }, {
    title: "CELEBRATING IEEE - DAY4: TOURNAMENT     ",
    description:"IEEE ENETCOM SB organized a tournament in which ISIMS Student Branch, ENIS Student Branch, and ENETCOM Student Branch participated. It was a great opportunity to gather different Student Branches' members. At the same time, participants practiced the sport they love. In fact, in IEEE days we always try to make our members happy and satisfied more than at any time.",
    img: a9,
  }, {
    title: "CELEBRATING IEEE - DAY1: PHYSICAL ACTIVITIES ",
    description:"Celebrating our IEEE, IEEE ENETCOM SB organized a physical fun event. As 80 members of our SB gathered at our university campus and played a variety of sports games. We hit the goal of breaking the ice between members and creating a lovely atmosphere.",
    img: a8,
  }, {
    title: "IEEE DAY CELEBRATIONS     ",
    description:"As a part of the celebrations of the IEEE Day 2022, IEEE Computer Society Student Branch participated in four events to make the festivities bigger and more beneficial.A healthy mind in a healthy body, this is why we started the celebrations with Sports activities in which members built teams to compete in outdoor games. The second day was an online Competitive Programming training session. Participant got famliar with the basics of programming and they got introduced to CodeForces platform in order to practice codingKeeping up with the Competitive Programming, the third celebration day was a second in-person training session. With the assistance of IEEE CS Officers, members solved real coding problems to use their knowledge. Closing the celebrations with a gathering event. IEEE ENET'Com SB in collaboration with IEEE ISIMS SB, organised a meet-up with a vibrant schedule in which they cherished the sense of IEEE community. The program also including brainstorming activities and a competition to make members familiar with the upcoming challenges in TSYP. ",
    img: a7,
  },
  {
    title: "HAPPY IEEE DAY ",
    description:"IEEE ENETCOM Student Branch celebrated the IEEE DAY 2022 with ISIMS Student Branch. It was a great day full of fun and entertainment!  The games were delightful and varied. Some promote teamwork, while others test general culture. There was a quiz that was appreciated by many. Questions were about IEEE, outer space as it is the theme of the IEEE Day in Tunisia Section, Tunisian history as on 15 October Tunisians celebrate Evacuation Day ( the date of the departure of the last occupying soldier from Tunisian territory), the Football World Cup, and of course, some questions were about Pink October. In fact, the WIE IEEE ENETCOM Affinity Group took the opportunity to remind the importance of Pink October and distributed the ribbons. Also, attendees participated in a competition of innovative ideas in which they worked in groups and got the chance to express themselves.Finally, we sang together our favorite songs, especially those from our childhood. Before they left we asked them to write feedback on the IEEE letters hanging on the wall. All of them were heartwarming!! They give us great energy to go forward and increase our determination to work and be more creative. ",
    img: a6,
  },
  {
    title: "SPONSORING WORKSHOP",
    description:"Looking for sponsorship opportunities is a major part of the success of any event, especially a non-lucrative organization's event.IEEE ENETCOM SB organized a sponsoring workshop to prepare members for upcoming events.  ",
    img: a5,
  },
  {
    title: "OUR FIRST GENERAL ASSEMBLY  ",
    description:" We gathered IEEE volunteers to share with them our vision for this year and to give them a glance at the upcoming events. Best way to motivate them and involve them in our Family. First, we clarified the IEEE's name: the Institute of Electrical and Electronics Engineering. Also, we explained different concepts related to IEEE: our mission in Advancing Technology for Humanity, Tunisia Section, and Region 8. Then, we focused on our Student Branch: its history, its chapters, and Affinity Group.Each chapter and Affinity Group shared its plan till January 2023 and shared the work that has been accomplished since January 2022. Finally, we presented and explained the upcoming mind-blowing events. The lovely IEEE Day, the esteemed Xtreme, the famous TSYP, and the first of its kind WIE ACT (WIE Annual Congress of Tunisia). ",
    img: a4,
  },
  {
    title: "INTEGRATION DAY",
    description:
"Meeting new students!Telling them about IEEE ENET'COM SB!Sharing our great and exciting moments!It was a heartwarming experience! ",
    img: a3,
  },
  {
    title: "UNIVEXPO  ",
    description: 
        "We set up two stands during this 3-day fair: The first one is in collaboration with IEEE ENIS Student Branch, IEEE FSS Student Branch, and IEEE ISIMS  Student Branch to present IEEE, our activities, chapters, affinity groups, goals, and ethics. The second stand had been organized with ENETCom administration, students, and clubs as part of the orientation consult given to bachelors and pre-engineering students by the universities of Sfax.Preparing for the integration day and other details Brainstorming with officers",
    img: a2,
  },
  {
    title: "COLLECTION OF DONS ON THE OCCASION OF RAMADAN ",
    description:
      "Since Ramadan is the month of Compassion, Generosity, Honesty, and Selflessness, we as Muslim brothers and sisters are bound to give, share and take care of each others .Thus, in honor of this special month, we decided to organize 'قفة رمضان' between the 28th of March and the 9th of April to help those in need and that will be through collecting donations. Those will be used to buy goods and essentials for these people. "
      , img: a1,
  },
  ];
return (
  <div>
    <Header
      background={
        backgroundChapters
      }
      text="activities"
    />
    <Chrono mode="VERTICAL"
      lineWidth={5}
    >
      {content.map((item, i) =>
        i % 2 == 0 ? (

          <div
            className="timeline-container"

          >
            <div >
              <h3>{item.title}</h3>
              <p id="p">{item.description}</p>
            </div>
            <div>
              <img src={item.img} />
            </div>
          </div>
        ) : (
          <div
            className="timelineaa-container"
          >
            <div style={{ margin: "15px" }}>
              <h3>{item.title}</h3>
              <p id="p">{item.description}</p>
            </div>
            <div>
              <img src={item.img} />
            </div>
          </div>

        )
      )}</Chrono>
    <Footer />
  </div>
);
};
export default Activities;
