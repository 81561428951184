import React from "react";
import Slider from "react-slick";
import IAS from '../../assets/chapterslogo/ias.png'
import PES from '../../assets/chapterslogo/pes.png'
import EMBS from '../../assets/chapterslogo/embs.png'
import CAS from '../../assets/chapterslogo/cas.png'
import RAS from '../../assets/chapterslogo/ras.png'
import CS from '../../assets/chapterslogo/cs.png'
import WIE from '../../assets/chapterslogo/wie.png'
import './CHapters.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Chapters = () => {
  const img = [
    {
      id: 1,
      src: CS,
      alt: "First slide",
    },
    {
      id: 2,
      src: EMBS,
      alt: "Second slide",
    },
    {
      id: 3,
      src: IAS,
      alt: "Third slide",
    },
    {
      id: 3,
      src: PES,
      alt: "Third slide",
    },
    {
      id: 3,
      src: CAS,
      alt: "Third slide",
    },
    {
      id: 3,
      src: RAS,
      alt: "Third slide",
    },
    {
      id: 3,
      src: WIE,
      alt: "Third slide",
    },
    
  ];
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 2000,
    cssEase: "linear",
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
    <h1>Our Chapters & AFFINITY GROUP</h1>
      <Slider {...settings} style = {{width:"100%" , overflow :"hidden"}}>
        {img.map((item) => (
          <div key={item.id}>
            <img
              src={item.src}
              alt={item.alt}
              style={{
                padding : "10%",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Chapters;
