import React from 'react';
import "./Footer.css";
import { CDBFooter, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
import {
    Routes,
    Route,
    Link,
    Navigate,
    NavLink,
  } from 'react-router-dom';

const Footer = () => {
   

  return (
    <CDBFooter className="shadow" style={{height:'11%'}}  >
      <CDBBox
        display="flex"
        justifyContent="around"
        alignItems="center"
        className="mx-auto py-4 flex-wrap"
        style={{ width: '100%', position:"sticky", bottom:0, left:0, right:0 }}
      >
        <CDBBox display="flex" alignItems="center">
          <a  className="d-flex align-items-center p-0 text-dark">
          <NavLink  to="/activities" />

          <small className="footeri">&copy; IEEE ENET'COM, 2024. All rights reserved.</small>
           
          </a>
        </CDBBox>
        
        <CDBBox display="flex">
           <a href="https://www.linkedin.com/company/ieeeenectomsb/">
          <CDBBtn flat style={{backgroundColor:"white",color:"#067EAE", border:"none"}} className=" p-3">
            <CDBIcon fab icon="linkedin" />
          </CDBBtn>
          </a>
        <a href="https://www.facebook.com/IEEEENETCOMSB">
          <CDBBtn flat style={{backgroundColor:"white",color:"#067EAE", border:"none"}}  className="mx-4 p-3">
            <CDBIcon fab icon="facebook-f" />
          </CDBBtn>
          </a>
         
          <a href="https://www.instagram.com/ieee.enetcom.sb/">
          <CDBBtn flat style={{backgroundColor:"white",color:"#067EAE", border:"none",padding:"0"}} className="p-3" >
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
          </a>
        </CDBBox>
      </CDBBox>
    </CDBFooter>
  )
}

export default Footer