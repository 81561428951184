import React from 'react'
import './NewExecBoard.css'
import board from '../../assets/newexecboard/board.png'
import chahine from '../../assets/newexecboard/Chahine.png'
import eya from '../../assets/newexecboard/Eya.png'
import khalil from '../../assets/newexecboard/Khalil.png'
import sakka from '../../assets/newexecboard/Sakka.png'
import yosr from '../../assets/newexecboard/Yossr.png'


const NewExecBoard = () => {
  return (
    <div>
    
        <div className='mainttext'>
            <p className='titlesb'>OUR EXECUTIVE BOARD</p>
            <p className='year'>2024 - 2025</p>    
        </div>

        <div className='board'>
            <img src={board} width='80%'/>

        </div>
    </div>
  )
}

export default NewExecBoard