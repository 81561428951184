import React from 'react';
import Header from '../components/aboutus/Header';
import Footer from '../components/Footer';
import './ContactUs.css';
const ContactUS = () => {
    return (
        <>
        <div className="contact">
            <h2 className="contact-title">
               Do you want to<br /> get in touch with us ?<br />
                Contact us !
            </h2>
            <form method='post' action='https://formsubmit.co/90b9dff7c0e7632e31e36aa2f8ec8e4a'>
            <div className="contact-form">
                <label htmlFor="name">
            <span className="input-name">
              Name:
            </span>
                    <input type="text" name="name" id="name" className="name" />
                </label>
                <label htmlFor="email">
            <span className="input-name">
              Email address:
            </span>
                    <input type="text" name="email" id="email" className="email" />
                </label>
                <label htmlFor="project-info">
            <span className="input-name">
              Tell about your project:
            </span>
                    <textarea name="project-info" id="project-info" className="project-info" defaultValue={""} />
                </label>
                <button type='submit' className="btn send-btn">
                    Send
                </button>
                
            </div>
            </form>
            
        </div></>
    );
}
export default ContactUS;