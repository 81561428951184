import React from "react";
import "./Numbers.css";
import members from "../../assets/numbers/members.png";
import legacy from "../../assets/numbers/legacy.png";
import awards from "../../assets/numbers/awards.png";
import officers from "../../assets/numbers/officers.png";
import events from "../../assets/numbers/events.png";


const Numbers = () => {
  return (
    <div className="mainn">
      <div className="titlen">
        <p className="frst">IEEE ENET'COM SB IN&nbsp;</p>
        <p className="nmbrs">NUMBERS</p>
      </div>
      <div className="flex">
        <div className="members">
          <img src={members} className="icon" />
          <p className="number">480+</p>
          <p className="numtext">
            Dedicated <br />
            Members
          </p>
        </div>
        <div className="members">
          <img src={legacy} className="icon" />
          <p className="number">11+</p>
          <p className="numtext">
            Years of <br />
            Legacy
          </p>
        </div>
        <div className="members">
        <img src={awards} className="icon"/>
          <p className="number">20+</p>
          <p className="numtext">
            Awards <br />
            Obtained
          </p>
          
        </div>
        <div className="members">
        <img src={officers} className="icon"/>
          <p className="number">30+</p>
          <p className="numtext">
            Hard-Working <br />
            Officers
          </p>
          
        </div>
        <div className="members">
        <img src={events} className="icon"/>
          <p className="number">80+</p>
          <p className="numtext">
            Events <br />
            Organized
          </p>
          
        </div>

      </div>
    </div>
  );
};

export default Numbers;
