import React from "react";
import './Header.css';
const Header = ({background,text}) => {
  return (
    <div className="header-component" style={{backgroundImage:`url(${background})`}}>
      <div className="header-component__title">
        {text}
      </div>
    </div>
  );
};

export default Header;
