import React from "react";
import './Section.css'
const Section = ({img,description}) => {
  return (
    <div
      className="section-container"
      style={{ display: "flex"}}
    >
        <img
          src={img}
          width="40%"
        />
      <div className="section-text">
        {description}
      </div>
    </div>
  );
};

export default Section;
